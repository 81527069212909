import axios from "axios";
import Config from '@/config/index.js'
import store from '@/store'
const LoadFiles = async () => {
  const token = store.getters.token;

  var result = await axios
    .get(
      `${Config.baseAPIPath}/ListFiles`,
      { headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` } }
    );
  return result.data;
}

const DeleteFile = async (name) => {
  const token = store.getters.token;

  var result = await axios
  .post(
    `${Config.baseAPIPath}/DeleteFile`,
    {"name": name, "type": "file"},
    { 
      headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` },
    }
  );
  return result.data;
}

export default {
  LoadFiles,
  DeleteFile
}