<template>
  <PageContent>
    <SubHeading>File Management</SubHeading>
    <AdminBreadCrumb />
    Upload a File (Please avoid spaces in file names)...
    <FileUpload type="file" @file-uploaded="FileLoaded" />
    <hr>
    <div class="files">
      <div v-for="file in files" :key="file" class="fileRow">
        <div class="fileName">{{file}} [/files/{{file}}]</div>
        <div class="between"></div>
        <div>
          <button @click="DeleteFile(file)">Delete File</button>
        </div>
      </div>
    </div>
  </PageContent>
</template>
<style scoped>
  .fileRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .fileName {
    vertical-align: bottom;
  }
  .between {
    flex: 1;
    border-bottom: dotted rgba(0,0,0,0.1);
    margin: 0 15px 0 15px;
  }
</style>
<script>
import PageContent from '@/components/PageContent'
import SubHeading from '@/components/SubHeading'
import FileUpload from '@/components/FileUpload'
import AdminBreadCrumb from '@/components/Admin/AdminBreadCrumb'

import FileService from '@/services/FileService'

export default {
  name: "EditCards",
  components: {
    PageContent,
    SubHeading,
    FileUpload,
    AdminBreadCrumb
  },
  data() {
    return {
      files: [],
      filesLoaded: false
    }
  },
  methods: {
    async FileLoaded() {
      alert("File Uploaded");
      await this.LoadAllFiles();
    },
    async LoadAllFiles() {
      this.files = await FileService.LoadFiles();
      this.filesLoaded = true;
    },
    async DeleteFile(file) {
      await FileService.DeleteFile(file);
      await this.LoadAllFiles();
    }
  },
  async mounted() {
    await this.LoadAllFiles();
  }
}

</script>